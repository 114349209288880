import "core-js/modules/es.array.push.js";
import { getMessageList } from '@/service/index.server';
export default {
  name: "news",
  data() {
    return {
      list: [],
      currentPage: 1,
      pageName: '学会要闻',
      contentList: [],
      page: 1,
      pageSize: 10,
      count: 0
    };
  },
  mounted() {
    this.list = this.$route.meta.menu;
    this.checkoutSelected();
    // 1学会动态,2法治新闻,3区县法学,4学术委员会,
    if (this.$route.query.id) {
      this.getList(this.$route.query.id);
    }
  },
  methods: {
    pageChange(val) {
      this.page = val;
      this.getList(this.$route.query.id);
    },
    handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/newsDetail',
        query: {
          key: item.id
        }
      });
    },
    getList(type) {
      getMessageList({
        page: this.page,
        pageSize: this.pageSize,
        type
      }).then(res => {
        this.contentList = res.data;
        this.count = res.count;
      });
    },
    handleNews(id) {
      if (id) {
        this.$router.push({
          query: {
            id
          }
        });
      }
    },
    checkoutSelected() {
      if (this.$route.query.id) {
        this.currentPage = parseInt(this.$route.query.id);
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].value === this.currentPage) {
            this.pageName = this.list[i].name;
          }
        }
      }
    },
    listItemMonths(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    getText(str) {
      if (str) {
        return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
      } else {
        return '';
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.checkoutSelected();
        if (this.$route.query.id) {
          this.page = 1;
          this.getList(this.$route.query.id);
        }
      },
      deep: true
    }
  }
};