import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  class: "mt-30 talent-info-box flex-row"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "ml-60"
};
const _hoisted_6 = {
  class: "detail-name"
};
const _hoisted_7 = {
  class: "mt-35 detail-item-1"
};
const _hoisted_8 = {
  class: "mt-25 detail-item-1"
};
const _hoisted_9 = {
  class: "mt-25 detail-item-1"
};
const _hoisted_10 = {
  class: "detail-item-box flex-row mt-25"
};
const _hoisted_11 = {
  class: "flex-row"
};
const _hoisted_12 = {
  class: "mt-20 talent-info-box"
};
const _hoisted_13 = {
  class: "detail-bottom-content mt-25"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createTextVNode("当前位置：")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[3] || (_cache[3] = _createTextVNode(">")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$back(-1))
  }, "法律咨询专家库"), _cache[4] || (_cache[4] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    src: $data.detail.headPortraitUrl,
    alt: "",
    class: "detail-photo-size"
  }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString($data.detail.name) + _toDisplayString($options.typeFilter($data.detail.type)), 1), _createElementVNode("p", _hoisted_7, [_cache[5] || (_cache[5] = _createElementVNode("span", {
    class: "detail-title"
  }, "职业年限：", -1)), _createTextVNode(_toDisplayString($options.yearFormat($data.detail.certificateStartTime)), 1)]), _createElementVNode("p", _hoisted_8, [_cache[6] || (_cache[6] = _createElementVNode("span", {
    class: "detail-title"
  }, "所属机构：", -1)), _createTextVNode(_toDisplayString($data.detail.unitName), 1)]), _createElementVNode("p", _hoisted_9, [_cache[7] || (_cache[7] = _createElementVNode("span", {
    class: "detail-title"
  }, "执业地区：", -1)), _createTextVNode(_toDisplayString($data.detail.area), 1)]), _createElementVNode("div", _hoisted_10, [_cache[8] || (_cache[8] = _createElementVNode("span", {
    class: "detail-title"
  }, "擅长领域：", -1)), _createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.detail.lawLabels, (item, index) => {
    return _openBlock(), _createElementBlock("p", {
      class: _normalizeClass({
        'ml-10': index !== 0
      }),
      key: index
    }, _toDisplayString(item.name), 3);
  }), 128))])])])]), _createElementVNode("div", _hoisted_12, [_cache[9] || (_cache[9] = _createElementVNode("p", {
    class: "detail-bottom-title"
  }, "个人介绍", -1)), _createElementVNode("p", _hoisted_13, _toDisplayString($data.detail.introduction), 1)])]);
}