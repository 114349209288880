import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  class: "mt-30 flex-row jus-between"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "view-content pt-0 flex flex-column jus-between"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  class: "flex-row"
};
const _hoisted_8 = {
  class: "date-box flex flex-column align-center"
};
const _hoisted_9 = {
  class: "month-container flex-center"
};
const _hoisted_10 = {
  class: "date-container flex-center"
};
const _hoisted_11 = {
  class: "ml-20 flex flex-column jus-between short-news"
};
const _hoisted_12 = {
  class: "list-item-title nowap-ellipsis"
};
const _hoisted_13 = {
  class: "list-item-info"
};
const _hoisted_14 = {
  class: "ml-20"
};
const _hoisted_15 = {
  class: "list-item-content mt-10"
};
const _hoisted_16 = {
  class: "flex-center mt-30"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createTextVNode("当前位置：")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[2] || (_cache[2] = _createTextVNode(">")), _cache[3] || (_cache[3] = _createElementVNode("span", null, "学会要闻", -1)), _cache[4] || (_cache[4] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["flex-center", {
        'left-title': !item.value,
        'left-list-item': !!item.value,
        'cursor-pointer': !!item.value
      }]),
      key: index,
      onClick: $event => $options.handleNews(item.value)
    }, [_createElementVNode("div", {
      class: _normalizeClass({
        'color-white': !item.value,
        'selected-item': item.value === $data.currentPage,
        'menu-item': item.value && item.value !== $data.currentPage
      })
    }, _toDisplayString(item.name), 3)], 10, _hoisted_4);
  }), 128))]), _createElementVNode("div", _hoisted_5, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.contentList, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: "list-item cursor-pointer",
      onClick: $event => $options.handleToDetail(item)
    }, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString($options.listItemMonths(item.releaseTime)) + "月", 1), _createElementVNode("div", _hoisted_10, _toDisplayString($options.listItemDate(item.releaseTime)), 1)]), _createElementVNode("div", _hoisted_11, [_createElementVNode("p", _hoisted_12, _toDisplayString(item.title), 1), _createElementVNode("p", _hoisted_13, [_createElementVNode("span", null, "来源：" + _toDisplayString(item.source), 1), _createElementVNode("span", _hoisted_14, "作者：" + _toDisplayString(item.author), 1)])])]), _createElementVNode("p", _hoisted_15, _toDisplayString($options.getText(item.content)), 1)], 8, _hoisted_6);
  }), 128))]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_el_pagination, {
    background: "",
    layout: "total,prev, pager, next",
    onCurrentChange: $options.pageChange,
    "current-page": $data.page,
    total: $data.count
  }, null, 8, ["onCurrentChange", "current-page", "total"])])])])]);
}