import "core-js/modules/es.array.push.js";
import { getMessageList } from '@/service/index.server';
export default {
  name: "announcement",
  data() {
    return {
      pageName: '',
      contentList: [],
      pageSize: 10,
      page: 1,
      count: 0
    };
  },
  mounted() {
    this.pageName = this.$route.name;
    this.getList();
  },
  methods: {
    // back(){
    //   this.$router.push('/');
    //   // this.$router.back(val);
    // },
    pageChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      getMessageList({
        pageSize: this.pageSize,
        page: this.page,
        type: 11
      }).then(res => {
        this.contentList = res.data;
        this.count = res.count;
      });
    },
    listItemMonths(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/announcementDetail',
        query: {
          id: item.id
        }
      });
    },
    getText(str) {
      return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
    }
  }
};