import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  class: "mt-30 flex-row jus-between"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "view-content pt-0"
};
const _hoisted_6 = {
  key: 0,
  class: "vip-apply mt-40"
};
const _hoisted_7 = {
  key: 1,
  class: "message-box"
};
const _hoisted_8 = {
  class: "flex-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[8] || (_cache[8] = _createTextVNode("当前位置：")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[9] || (_cache[9] = _createTextVNode(">")), _cache[10] || (_cache[10] = _createElementVNode("span", null, "会员工作", -1)), _cache[11] || (_cache[11] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["flex-center", {
        'left-title': !item.value,
        'left-list-item': !!item.value,
        'cursor-pointer': !!item.value
      }]),
      key: index,
      onClick: $event => $options.handleNews(item.value)
    }, [_createElementVNode("div", {
      class: _normalizeClass({
        'color-white': !item.value,
        'selected-item': item.value === $data.currentPage,
        'menu-item': item.value && item.value !== $data.currentPage
      })
    }, _toDisplayString(item.name), 3)], 10, _hoisted_4);
  }), 128))]), _createElementVNode("div", _hoisted_5, [_ctx.$route.query.id === '1' ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("button", {
    onClick: _cache[1] || (_cache[1] = (...args) => $options.jumpTo && $options.jumpTo(...args))
  }, _cache[12] || (_cache[12] = [_createElementVNode("span", null, "立即申请", -1)]))])) : (_openBlock(), _createElementBlock("div", _hoisted_7, [_cache[14] || (_cache[14] = _createElementVNode("p", null, "网站留言", -1)), _createVNode(_component_el_form, {
    "label-position": "right",
    "label-width": "100px",
    model: $data.form,
    class: "form-item-box",
    rules: $data.rules,
    ref: "form"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "留言标题",
      prop: "title"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.form.title,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.form.title = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "联系人",
      prop: "contact"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.form.contact,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.form.contact = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "联系电话",
      prop: "phone"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.form.phone,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.form.phone = $event)
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "QQ",
      prop: "qq"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.form.qq,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.form.qq = $event),
        modelModifiers: {
          number: true
        }
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "留言内容",
      prop: "content"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.form.content,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.form.content = $event),
        rows: 5,
        type: "textarea"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createElementVNode("div", _hoisted_8, [_createElementVNode("button", {
      class: "mt-40",
      onClick: _cache[7] || (_cache[7] = (...args) => $options.submitForm && $options.submitForm(...args))
    }, _cache[13] || (_cache[13] = [_createElementVNode("span", {
      class: "form-submit-text"
    }, "提交", -1)]))])]),
    _: 1
  }, 8, ["model", "rules"])]))])])]);
}