import "core-js/modules/es.array.push.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { ref } from "vue";
let locale = ref(zhCn);
import { getBanner } from '@/service/index.server';
import navigation from '@/components/navigation';
export default {
  name: "mainBox",
  components: {
    navigation
  },
  data() {
    return {
      keyword: '',
      locale,
      banner: require("../assets/top-bg.png")
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      getBanner({
        type: 2
      }).then(res => {
        if (res.data && res.data.imageUrl) {
          this.banner = res.data.imageUrl;
        }
      });
    },
    handleSearch() {
      if (this.keyword) {
        this.$router.push({
          path: '/mainBox/searchList',
          query: {
            keyword: this.keyword
          }
        });
      }
    }
  }
};