import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/home-right-qr.png';
const _hoisted_1 = {
  class: "right-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("img", {
    src: _imports_0,
    alt: "",
    class: "qr-size"
  }, null, -1), _createElementVNode("span", {
    class: "scan-text mt-8"
  }, "扫一扫，关注公众号", -1)]));
}