import "core-js/modules/es.array.push.js";
import selectComponent from '@/components/selectComponent';
import { getLabelList, getLawyerList } from '@/service/index.server';
import { getAllRepo } from '../service/index.server';
export default {
  components: {
    selectComponent
  },
  name: "talent",
  data() {
    return {
      isShow: false,
      pageName: '',
      contentList: [],
      selectList1: [],
      selectList2: [{
        name: '律师',
        id: 1
      }, {
        name: '专家',
        id: 2
      }],
      page: 1,
      pageSize: 5,
      labelId: 0,
      type: 1,
      count: 0,
      repoList: []
    };
  },
  mounted() {
    this.pageName = this.$route.name;
    this.getLabelList();
    this.getLawyerList();
    this.getRepoData();
  },
  methods: {
    toTalentList(id) {
      this.$router.push(`/mainBox/talentList?id=${id}`);
    },
    toHeadList() {
      this.$router.push('/mainBox/talentList');
    },
    async getRepoData() {
      const res = await getAllRepo();
      this.repoList = res.data;
    },
    pageChange(val) {
      this.page = val;
      this.getLawyerList();
    },
    selectResult(item, type) {
      if (type === '1') {
        this.labelId = item.id;
      } else if (type === '2') {
        this.type = item.id;
      }
      this.page = 1;
      this.getLawyerList();
    },
    toDetail(id) {
      this.$router.push({
        path: '/mainBox/talentDetail',
        query: {
          id
        }
      });
    },
    getLabelList() {
      getLabelList().then(res => {
        this.selectList1 = res.data;
      });
    },
    getLawyerList() {
      getLawyerList({
        page: this.page,
        pageSize: this.pageSize,
        labelId: this.labelId,
        type: this.type
      }).then(res => {
        this.contentList = res.data;
        this.count = res.count;
      });
    },
    yearFormat(val) {
      let d1 = new Date(val).getTime();
      let d2 = new Date().getTime();
      let time = Math.round((d2 - d1) / 1000 / 60 / 60 / 24 / 365);
      let string = `持证从业${time}年`;
      return time ? string : '';
    }
  }
};