import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  class: "mt-30 flex-row jus-between"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  key: 0,
  class: "view-content-1"
};
const _hoisted_6 = {
  class: "intro-leader-info-title-box flex justify-center"
};
const _hoisted_7 = {
  class: "leader-box pt-40"
};
const _hoisted_8 = {
  key: 0,
  class: "mb-50"
};
const _hoisted_9 = {
  class: "leader-title"
};
const _hoisted_10 = {
  class: "flex flex-wrap"
};
const _hoisted_11 = ["src"];
const _hoisted_12 = {
  class: "mt-10"
};
const _hoisted_13 = {
  key: 1,
  class: "mb-50"
};
const _hoisted_14 = {
  class: "leader-title"
};
const _hoisted_15 = {
  class: "flex flex-wrap"
};
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  class: "mt-10"
};
const _hoisted_18 = {
  key: 1,
  class: "view-content flex-column align-center"
};
const _hoisted_19 = {
  class: "flex-column align-center"
};
const _hoisted_20 = {
  class: "text-title"
};
const _hoisted_21 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createTextVNode("当前位置：")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[2] || (_cache[2] = _createTextVNode(">")), _cache[3] || (_cache[3] = _createElementVNode("span", null, "单位简介", -1)), _cache[4] || (_cache[4] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["flex-center", {
        'left-title': !item.value,
        'left-list-item': !!item.value,
        'cursor-pointer': !!item.value
      }]),
      key: index,
      onClick: $event => $options.handleIntro(item.value)
    }, [_createElementVNode("div", {
      class: _normalizeClass({
        'color-white': !item.value,
        'selected-item': item.value === $data.currentPage,
        'menu-item': item.value && item.value !== $data.currentPage
      })
    }, _toDisplayString(item.name), 3)], 10, _hoisted_4);
  }), 128))]), $data.currentPage === 3 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("p", null, _toDisplayString($data.leaderTitle), 1)]), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.leasers, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index
    }, [$options.handleFlag(index) ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, _toDisplayString(item.title) + "：", 1), _createElementVNode("div", _hoisted_10, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (items, indexs) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{
          'ml-55': indexs % 3
        }, "flex flex-column align-center leader-img-1-box"]),
        key: indexs
      }, [_createElementVNode("img", {
        src: items.headPortraitUrl,
        alt: "",
        class: "leader-img-1"
      }, null, 8, _hoisted_11), _createElementVNode("p", _hoisted_12, _toDisplayString(items.name), 1)], 2);
    }), 128))])])) : (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("p", _hoisted_14, _toDisplayString(item.title) + "：", 1), _createElementVNode("div", _hoisted_15, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (items, indexs) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{
          'ml-55': indexs % 4
        }, "flex flex-column align-center leader-img-2-box"]),
        key: indexs
      }, [_createElementVNode("img", {
        src: items.headPortraitUrl,
        alt: "",
        class: "leader-img-2"
      }, null, 8, _hoisted_16), _createElementVNode("p", _hoisted_17, _toDisplayString(items.name), 1)], 2);
    }), 128))])]))]);
  }), 128))])])) : (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_createElementVNode("p", _hoisted_20, _toDisplayString($data.responseData.title), 1)]), _createElementVNode("div", {
    class: "text-body fs-16 letter-spacing-2",
    innerHTML: $data.responseData.content
  }, null, 8, _hoisted_21)]))])]);
}