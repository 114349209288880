const apiBaseUrl = (process.env.NODE_ENV === 'production') ? {
  'base':'https://www.gysfxh.org.cn',
} : {
  'base':'http://www.gysfxh.org.cn',
  // 'base':'http://fxh.zijingtaiji.com',
  // 'base':'http://47.96.21.28:7810',
};

// 图片上传api，可参考具体用到的地方
const uploadImageBaseUrl = process.env.NODE_ENV === 'production' ?
  '' :
  '';

export {
  apiBaseUrl,
  uploadImageBaseUrl,
};
