import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  class: "mt-30 announcement-content-box flex flex-column jus-between"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "flex-row"
};
const _hoisted_6 = {
  class: "date-box flex flex-column align-center"
};
const _hoisted_7 = {
  class: "month-container flex-center"
};
const _hoisted_8 = {
  class: "date-container flex-center"
};
const _hoisted_9 = {
  class: "ml-20 flex flex-column jus-between"
};
const _hoisted_10 = {
  class: "list-item-title"
};
const _hoisted_11 = {
  class: "list-item-info"
};
const _hoisted_12 = {
  class: "ml-20"
};
const _hoisted_13 = {
  class: "list-item-content mt-10"
};
const _hoisted_14 = {
  class: "flex-center mt-30"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createTextVNode("当前位置：")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[2] || (_cache[2] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.contentList, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: "list-item cursor-pointer",
      onClick: $event => $options.handleToDetail(item)
    }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString($options.listItemMonths(item.releaseTime)) + "月", 1), _createElementVNode("div", _hoisted_8, _toDisplayString($options.listItemDate(item.releaseTime)), 1)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString(item.title), 1), _createElementVNode("p", _hoisted_11, [_createElementVNode("span", null, "来源：" + _toDisplayString(item.source), 1), _createElementVNode("span", _hoisted_12, "作者：" + _toDisplayString(item.author), 1)])])]), _createElementVNode("p", _hoisted_13, _toDisplayString($options.getText(item.content)), 1)], 8, _hoisted_4);
  }), 128))]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_el_pagination, {
    background: "",
    layout: "total,prev, pager, next",
    onCurrentChange: $options.pageChange,
    "current-page": $data.page,
    total: $data.count
  }, null, 8, ["onCurrentChange", "current-page", "total"])])])]);
}