import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "layer8 flex-col"
};
const _hoisted_2 = {
  class: "main9 flex-row"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  key: 0,
  class: "nav-item-box"
};
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      class: _normalizeClass(["word69 cursor-pointer position-relative", {
        'bg-CE1B1B': item.value === $data.currentTab
      }]),
      key: index,
      onClick: _withModifiers($event => $options.toPage(item.value), ["self"])
    }, [_createTextVNode(_toDisplayString(item.name) + " ", 1), item.list && item.list.length ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (items, indexs) => {
      return _openBlock(), _createElementBlock("div", {
        class: "",
        key: indexs
      }, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "white-line"
      }, null, -1)), _createElementVNode("li", {
        onClick: _withModifiers($event => $options.toPage(item.value, items.value), ["self"])
      }, _toDisplayString(items.name), 9, _hoisted_5)]);
    }), 128))])) : _createCommentVNode("", true)], 10, _hoisted_3);
  }), 128))])]);
}