import {base} from './request';

//列表信息查询
export const getMessageList = (params) => {
  return base.get(`v1/pc_law_association/query`,{params});
};
//单位简介相关数据
export const getIntroList = (params) => {
  return base.get(`v1/pc_law_unit_introduced/query_type`,{params});
};
//查询单位领导
export const getLeaderList = () => {
  return base.get(`v1/pc_law_unit_leadership/query_one`);
};
//新闻详情
export const getNewsDetail = (params) => {
  return base.get(`v1/pc_law_association/query_one`,{params});
};
//通知公告列表
export const getNoticeList = (params) => {
  return base.get(`v1/pc_law_announcement/query`,{params});
};
//通知公告详情
export const getNoticeDetail = (params) => {
  return base.get(`v1/pc_law_announcement/query_one`,{params});
};
//留言
export const sendMessage = (data) => {
  return base.post(`v1/pc_law_message_board/add`,data);
};
//首页查询
export const homeInfo = () => {
  return base.get(`v1/pc_home/query_news`);
};
//头条要闻
export const getHeadlines = () => {
  return base.get(`v1/pc_home/query_head_news`);
};
//分页查询头条要闻
export const getHeadlinesList = (params) => {
  return base.get(`v1/pc_law_lead_news/query`,{params});
};
//头条要闻详情
export const getHeadlinesDetail = (params) => {
  return base.get(`v1/law_association/query_one`,{params});
};
//首页轮播
export const getSwiperList = () => {
  return base.get(`v1/pc_home/query_shuffling`);
};
//查询标签列表
export const getLabelList = () => {
  return base.get(`v1/pc_law_label/query`);
};
//律师专家人才列表
export const getLawyerList = (params) => {
  return base.get(`v1/wx_law_lawyer_experts/query`,{params});
};
//律师专家人才详情
export const getLawyerInfo = (params) => {
  return base.get(`v1/wx_law_lawyer_experts/query_one`,{params});
};
//搜索列表
export const getSearchResult = (params) => {
  return base.get(`v1/pc_home/query_keyword`,{params});
};
//查询banner
export const getBanner = (params) => {
  return base.get(`v1/pc_banner/query`,{params});
};

//增加浏览记录
export const sendView = (data) => {
  return base.post(`v1/statistics/add`,data);
};

//查询所有库别
export const getAllRepo = () => {
  return base.get(`v1/pc_law_library_type/list`);
};

//查询首席专家库
export const getHeadSpecialist = (params) => {
  return base.get(`v1/pc_law_head_consult_specialist_library/query`, {params});
};

//查询专家库
export const getSpecialist = (params) => {
  return base.get(`v1/pc_law_consult_specialist_library/query`, {params});
};
