import { createApp } from 'vue'
import App from './App.vue'
import '@/style/common.scss';
import '@/router/index.js';
import router from './router/index.js';
import {back} from './utils/index.js';
// import 'amfe-flexible';
router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0;
  next();
});
const app = createApp(App);
app.use(router);
app.config.globalProperties.$back = back;
app.mount('#app');