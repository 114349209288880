export default {
  name: "selectComponent",
  props: ['list', 'type', 'currentItemValue'],
  data() {
    return {
      flag: true,
      currentItemName: '不限'
      // currentItemValue:0,
    };
  },
  mounted() {
    document.addEventListener('click', e => {
      if (!this.$el.contains(e.target)) {
        this.flag = true; //这句话的意思是点击其他区域关闭（也可以根据自己需求写触发事件）
      }
    });
    console.log('currentItemValue-----', this.currentItemValue);
    this.list.forEach(item => {
      if (item.id == this.currentItemValue) {
        this.currentItemName = item.name;
      }
    });
  },
  methods: {
    handleShow() {
      this.flag = !this.flag;
    },
    selectItem(item) {
      this.currentItemName = item.name;
      // eslint-disable-next-line vue/no-mutating-props
      this.currentItemValue = item.id;
      this.handleShow();
      this.$emit('selectResult', item, this.type);
    }
  }
};