export default {
  name: "foot",
  data() {
    return {
      list: [{
        title: '中国法学会',
        url: 'https://www.chinalaw.org.cn/'
      }, {
        title: '贵州省法学会',
        url: 'http://www.gzsfxh.org.cn/'
      }, {
        title: '贵阳市人民政府网',
        url: 'http://www.guiyang.gov.cn/'
      }, {
        title: '贵州省人民政府网',
        url: 'http://www.guizhou.gov.cn/'
      }, {
        title: '贵州省高级人民法院',
        url: 'http://www.guizhoucourt.gov.cn/'
      }]
    };
  },
  mounted() {},
  methods: {}
};