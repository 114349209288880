import axios from 'axios';
import {apiBaseUrl} from './serverUrl';
import { encryptV2 } from '@/utils/aesUtil'

// var timestamp = Date.parse(new Date());

// const key = 'asgjhhgzxcbnm4bUGfg' + timestamp / 1000 + '4bUGfgzxcbnmddui'
// const aesSession = encryptV2(key, 'noCf3De4UjmCnOWe')
const base = axios.create({
  'baseURL': apiBaseUrl.base,
  'headers': {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  validateStatus: (status) => {
    return status < 400;
  }
});
const uploadimg = axios.create({
  'baseURL': 'img',
  'headers': {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  validateStatus: (status) => {
    return status < 400;
  }
});
const req = {
  'resolve': config => {
    // console.log(Date.parse(new Date()));
    var timestamp = Date.parse(new Date());
    const key = 'asgjhhgzxcbnm4bUGfg' + timestamp / 1000 + '4bUGfgzxcbnmddui'
    const aesSession = encryptV2(key, 'noCf3De4UjmCnOWe')
    config.headers['gyfxhsession'] = aesSession
    // if(config.baseURL === "img"){
    //   if (localStorage.Authorization) {
    //     config.headers.token = localStorage.Authorization;
    //   }
    // }else {
    //   if (localStorage.Authorization) {
    //     config.headers.Authorization = localStorage.Authorization;
    //   }
    // }

    return config;
  },
  'reject': (error) => {
    return Promise.reject('req', error);
  }
};

const res = {
  'resolve': function (response) {
    if(response.status === 200 && response.data){
      return response.data;
    }
  },
  'reject': function (error) {
    return Promise.reject(error.response.data);
  }
};
base.interceptors.request.use(req.resolve, req.reject);
uploadimg.interceptors.request.use(req.resolve, req.reject);
base.interceptors.response.use(res.resolve, res.reject);
uploadimg.interceptors.response.use(res.resolve, res.reject);

export {base,uploadimg};
