import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/main-logo.png';
const _hoisted_1 = {
  class: "main-container"
};
const _hoisted_2 = {
  class: "search-box"
};
const _hoisted_3 = {
  class: "main31 flex-row align-center"
};
const _hoisted_4 = {
  class: "block11 flex-col"
};
const _hoisted_5 = {
  class: "outer21 flex-row justify-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_navigation = _resolveComponent("navigation");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_config_provider = _resolveComponent("el-config-provider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "top-bg",
    style: _normalizeStyle({
      backgroundImage: `url(${$data.banner})`
    })
  }, [_createElementVNode("div", _hoisted_2, [_cache[5] || (_cache[5] = _createElementVNode("img", {
    src: _imports_0,
    alt: "",
    class: ""
  }, null, -1)), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_cache[3] || (_cache[3] = _createElementVNode("div", {
    class: "search-icon flex-col"
  }, null, -1)), _withDirectives(_createElementVNode("input", {
    class: "word11",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.keyword = $event),
    onKeyup: _cache[1] || (_cache[1] = _withKeys((...args) => $options.handleSearch && $options.handleSearch(...args), ["enter"])),
    placeholder: "请输入关键字搜索"
  }, null, 544), [[_vModelText, $data.keyword]])])]), _createElementVNode("button", {
    class: "block21 flex-col cursor-pointer",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.handleSearch && $options.handleSearch(...args))
  }, _cache[4] || (_cache[4] = [_createElementVNode("span", {
    class: "word21"
  }, "搜索", -1)]))])]), _createVNode(_component_navigation)], 4), _createVNode(_component_el_config_provider, {
    locale: $data.locale
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view, {
      class: "mt-30"
    })]),
    _: 1
  }, 8, ["locale"])]);
}