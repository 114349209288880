import "core-js/modules/es.array.push.js";
import { getMessageList, getHeadlinesList } from '@/service/index.server';
export default {
  name: "searchList",
  data() {
    return {
      pageName: '',
      contentList: [],
      pageSize: 10,
      page: 1,
      keyword: '',
      count: 0,
      type: 7
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    let title = this.$route.name;
    if (parseInt(this.type) === 8) {
      title = '法学研究';
    } else if (parseInt(this.type) === 9) {
      title = '法治宣传';
    } else if (parseInt(this.type) === 10) {
      title = '法律服务';
    }
    this.pageName = title;
    if (this.type) {
      this.getSearchResult();
    } else {
      this.getList();
    }
  },
  methods: {
    getList() {
      getHeadlinesList({
        page: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.contentList = res.data;
        this.count = res.count;
      });
    },
    pageChange(val) {
      console.log(val, this.type);
      this.page = val;
      if (this.type) {
        this.getSearchResult();
      } else {
        this.getList();
      }
    },
    listItemMonths(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    getSearchResult() {
      getMessageList({
        page: this.page,
        pageSize: this.pageSize,
        type: this.type
      }).then(res => {
        this.contentList = res.data;
        this.count = res.count;
      });
    },
    handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/newsDetail',
        query: {
          key: item.newsId || item.id,
          enter: 'list',
          type: this.type
        }
      });
    },
    getText(str) {
      if (str) {
        return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
      }
    }
  }
};