import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  class: "mt-30 talent-info-box"
};
const _hoisted_4 = {
  class: "flex justify-center rich-title"
};
const _hoisted_5 = {
  class: "rich-content-info flex justify-center mt-20"
};
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = {
  key: 0,
  class: "bottom-more mt-50"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[4] || (_cache[4] = _createTextVNode("当前位置：")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[5] || (_cache[5] = _createTextVNode(">")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$back(-1))
  }, "通知公告"), _cache[6] || (_cache[6] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString($data.detail.title), 1), _createElementVNode("p", _hoisted_5, [_createElementVNode("span", null, "来源：" + _toDisplayString($data.detail.source), 1), _cache[7] || (_cache[7] = _createTextVNode("   |   ")), _createElementVNode("span", null, "作者：" + _toDisplayString($data.detail.author), 1), _cache[8] || (_cache[8] = _createTextVNode("   |   ")), _createElementVNode("span", null, "发布时间：" + _toDisplayString($data.detail.releaseTime), 1)]), _cache[12] || (_cache[12] = _createElementVNode("div", {
    class: "rich-cut-line mt-35"
  }, null, -1)), _createElementVNode("div", {
    class: "mt-25 fs-16 letter-spacing-2 line-height-30",
    innerHTML: $data.detail.content
  }, null, 8, _hoisted_6), ($data.last.id || $data.next.id) && $data.flag ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_cache[11] || (_cache[11] = _createElementVNode("p", null, "其他内容继续浏览：", -1)), $data.last.id ? (_openBlock(), _createElementBlock("p", {
    key: 0,
    class: "mt-15 cursor-pointer",
    onClick: _cache[2] || (_cache[2] = $event => $options.lookOther($data.last))
  }, [_cache[9] || (_cache[9] = _createElementVNode("span", null, "上一篇：", -1)), _createTextVNode(" " + _toDisplayString($data.last.title), 1)])) : _createCommentVNode("", true), $data.next.id ? (_openBlock(), _createElementBlock("p", {
    key: 1,
    class: "mt-15 cursor-pointer",
    onClick: _cache[3] || (_cache[3] = $event => $options.lookOther($data.next))
  }, [_cache[10] || (_cache[10] = _createElementVNode("span", null, "下一篇：", -1)), _createTextVNode(" " + _toDisplayString($data.next.title), 1)])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]);
}