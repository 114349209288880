import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../assets/select-icon.png';
const _hoisted_1 = {
  class: "position-relative"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "select-box cursor-pointer",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handleShow && $options.handleShow(...args))
  }, [_createElementVNode("span", null, _toDisplayString($data.currentItemName), 1), _cache[2] || (_cache[2] = _createElementVNode("img", {
    src: _imports_0,
    alt: "",
    class: "select-icon-size"
  }, null, -1))]), _createElementVNode("ul", {
    class: _normalizeClass(["select-box-1", {
      'h-0': $data.flag
    }])
  }, [_createElementVNode("li", {
    onClick: _cache[1] || (_cache[1] = $event => $options.selectItem({
      name: '不限',
      id: 0
    })),
    class: _normalizeClass({
      'color-red': $props.currentItemValue === -1
    })
  }, "不限", 2), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      onClick: $event => $options.selectItem(item),
      class: _normalizeClass({
        'color-red': item.id === $props.currentItemValue
      })
    }, _toDisplayString(item.name), 11, _hoisted_2);
  }), 128))], 2)]);
}