import "core-js/modules/es.array.push.js";
import { getIntroList, getLeaderList } from '@/service/index.server';
export default {
  name: "intro",
  data() {
    return {
      list: [],
      currentPage: 1,
      pageName: '单位简介',
      responseData: {
        title: '111111111111111111',
        content: ''
      },
      leaderTitle: '',
      leasers: {},
      keyArray: []
    };
  },
  mounted() {
    this.list = this.$route.meta.menu;
    this.checkoutSelected();
    if (parseInt(this.$route.query.id) === 3) {
      this.getLeader();
    } else {
      if (parseInt(this.$route.query.id) === 5) {
        this.getList(4);
      } else if (parseInt(this.$route.query.id) === 4) {
        this.getList(3);
      } else {
        this.getList(this.$route.query.id);
      }
    }
  },
  methods: {
    getList(type) {
      getIntroList({
        type
      }).then(res => {
        if (res.data) {
          this.responseData = res.data;
        }
      });
    },
    getLeader() {
      getLeaderList().then(res => {
        this.leaderTitle = res.data.title;
        let list = res.data.lawUnitLeadershipList;
        let obj = {};
        for (let i = 0; i < list.length; i++) {
          if (obj[list[i].typeId]) {
            obj[list[i].typeId].list.push(list[i]);
          } else {
            obj[list[i].typeId] = {};
            obj[list[i].typeId].title = list[i].typeName;
            obj[list[i].typeId].list = [list[i]];
          }
        }
        this.leasers = obj;
        console.log(obj);
        this.keyArray = Object.keys(obj);
      });
    },
    handleIntro(id) {
      if (id) {
        this.$router.push({
          query: {
            id
          }
        });
      }
    },
    checkoutSelected() {
      if (this.$route.query.id) {
        this.currentPage = parseInt(this.$route.query.id);
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].value === this.currentPage) {
            this.pageName = this.list[i].name;
          }
        }
      }
    },
    handleFlag(index) {
      return index === this.keyArray[0];
    }
  },
  watch: {
    $route: {
      handler() {
        this.checkoutSelected();
        if (this.$route.query.id) {
          if (parseInt(this.$route.query.id) === 3) {
            this.getLeader();
          } else {
            if (parseInt(this.$route.query.id) === 5) {
              this.getList(4);
            } else if (parseInt(this.$route.query.id) === 4) {
              this.getList(3);
            } else {
              this.getList(this.$route.query.id);
            }
          }
        }
      },
      deep: true
    }
  }
};