import "core-js/modules/es.array.push.js";
import { getMessageList } from '@/service/index.server';
export default {
  name: "works",
  data() {
    return {
      list: [],
      currentPage: 1,
      pageName: '学会要闻',
      contentList: [],
      page: 1,
      pageSize: 10,
      count: 0
    };
  },
  mounted() {
    this.list = this.$route.meta.menu;
    this.checkoutSelected();
    if (this.$route.query.id === '1') {
      this.getList(5);
    } else if (this.$route.query.id === '2') {
      this.getList(6);
    } else if (this.$route.query.id === '3') {
      this.getList(7);
    }
  },
  methods: {
    pageChange(val) {
      this.page = val;
      if (this.$route.query.id === '1') {
        this.getList(5);
      } else if (this.$route.query.id === '2') {
        this.getList(6);
      } else if (this.$route.query.id === '3') {
        this.getList(7);
      }
    },
    getList(type) {
      getMessageList({
        page: this.page,
        pageSize: this.pageSize,
        type
      }).then(res => {
        this.contentList = res.data;
        this.count = res.count;
      });
    },
    handleWorks(id) {
      if (id) {
        this.$router.push({
          query: {
            id
          }
        });
      }
    },
    checkoutSelected() {
      if (this.$route.query.id) {
        this.currentPage = parseInt(this.$route.query.id);
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].value === this.currentPage) {
            this.pageName = this.list[i].name;
          }
        }
      }
    },
    handleToDetail(item) {
      this.$router.push({
        path: '/mainBox/newsDetail',
        query: {
          key: item.id
        }
      });
    },
    listItemMonths(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[1];
      }
    },
    listItemDate(val) {
      if (val) {
        let arr = val.split(' ');
        return arr[0].split('-')[2];
      }
    },
    getText(str) {
      return str.replace(/<[^<>]+>/g, "").replace(/&emsp;/gi, "").replace(/&nbsp;/gi, "");
    }
  },
  watch: {
    $route: {
      handler() {
        this.checkoutSelected();
        this.page = 1;
        if (this.$route.query.id === '1') {
          this.getList(5);
        } else if (this.$route.query.id === '2') {
          this.getList(6);
        } else if (this.$route.query.id === '3') {
          this.getList(7);
        }
      },
      deep: true
    }
  }
};