import "core-js/modules/es.array.push.js";
import { homeInfo, getHeadlines, getSwiperList, getBanner, getMessageList, sendView } from '@/service/index.server';
import Swiper from 'swiper';
//根据自己的版本修改css路径值
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css';
//不同版本引用的js路径也不一样，可以查看node_modules下面的swiper目录
import 'swiper/js/swiper.min.js';
import navigation from '@/components/navigation';
import rightQrCode from '@/components/rightQrCode';
export default {
  components: {
    navigation,
    rightQrCode
  },
  data() {
    return {
      keyword: '',
      imgList: [
        // { url: require("../assets/home-logo.png"),title:'1意犹未尽的冬奥会结束了，外媒还在回味' },
        // { url: require("../assets/home-logo.png"),title:'2意犹未尽的冬奥会结束了，外媒还在回味' },
        // { url: require("../assets/home-logo.png"),title:'3意犹未尽的冬奥会结束了，外媒还在回味' },
      ],
      list: [//社团管理
      ],
      list2: [//学会动态
      ],
      list3: [//区县法学
      ],
      list4: [//法学研究
      ],
      list5: [//通知公告
      ],
      list6: [//法治宣传
      ],
      list7: [//法律服务
      ],
      list8: [//头条要闻
      ],
      list9: [//学术委员会
      ],
      headlines: {},
      constants: {},
      banner: require("../assets/home-top-bg.png")
    };
  },
  mounted() {
    this.getList(); //各种列表
    this.getHeadlines(); //头条
    this.getSwiperList();
    this.getBanner();
    //学术委员会
    this.getNewList();
    this.viewRecord();
  },
  methods: {
    viewRecord() {
      sendView({
        type: 1
      });
    },
    getNewList() {
      getMessageList({
        page: 1,
        pageSize: 7,
        type: 4
      }).then(res => {
        console.log('asdasd--------------', res);
        res.data && (this.list8 = res.data);
      });
    },
    getBanner() {
      getBanner({
        type: 1
      }).then(res => {
        if (res.data && res.data.imageUrl) {
          this.banner = res.data.imageUrl;
        }
      });
    },
    toPage(type, id, newsType) {
      if (type === 1) {
        //学会要闻-学术委员会
        this.$router.push({
          path: '/mainBox/news',
          query: {
            id: 4
          }
        });
      } else if (type === 2) {
        //list-item跳转新闻详情
        if (newsType && newsType === 12) {
          window.open(id);
        } else {
          this.$router.push({
            path: '/mainBox/newsDetail',
            query: {
              key: id,
              enter: 'home'
            }
          });
        }
      } else if (type === 3) {
        //头条新闻
        this.$router.push({
          path: '/mainBox/headlinesDetail',
          query: {
            key: id,
            enter: 'home'
          }
        });
      } else if (type === 4) {
        //各种列表
        this.$router.push({
          path: '/mainBox/list',
          query: {
            type: id,
            enter: 'home'
          }
        });
      }
    },
    handleSearch() {
      if (this.keyword) {
        this.$router.push({
          path: '/mainBox/searchList',
          query: {
            keyword: this.keyword
          }
        });
      }
    },
    getHeadlines() {
      getHeadlines().then(res => {
        // this.list8 = res.data;
        if (res.data && res.data.length) {
          this.headlines = Object.assign({}, res.data[0]);
        }
        if (res.data && res.data.length > 1) {
          // this.list8 = res.data.splice(1,3);
        }
      });
    },
    getList() {
      // 新闻类型:1学会动态,2社团管理,3区县法学,4学术委员会,5.支部工作,6工会工作,7党史,守初心,8法学研究,9法治宣传,10法律服务，11通知公告
      homeInfo().then(res => {
        res.data[1] && (this.list2 = res.data[1].splice(0, 7));
        res.data[2] && (this.list = res.data[2].splice(0, 7));
        res.data[3] && (this.list3 = res.data[3].splice(0, 7));
        res.data[8] && (this.list4 = res.data[8].splice(0, 7));
        res.data[11] && (this.list5 = res.data[11].splice(0, 7));
        res.data[9] && (this.list6 = res.data[9].splice(0, 7));
        res.data[10] && (this.list7 = res.data[10].splice(0, 7));
        this.$nextTick(() => {
          let swiper = new Swiper(".swiper-container-2", {
            pagination: {//放配置
              // el: ".swiper-pagination",
              // bulletClass : 'my-bullet'
            },
            loop: true,
            autoplay: true,
            direction: 'vertical'
          });
          var comtainer = document.getElementsByClassName('swiper_container-2');
          comtainer.onmouseenter = function () {
            console.log('/////////1');
            swiper.stopAutoplay();
          };
          comtainer.onmouseleave = function () {
            console.log('/////////2');
            swiper.startAutoplay();
          };
        });
      });
    },
    getSwiperList() {
      getSwiperList().then(res => {
        res.data && (this.imgList = res.data);
        this.$nextTick(() => {
          new Swiper(".swiper-container", {
            pagination: {
              //放配置
              el: ".swiper-pagination"
              // bulletClass : 'my-bullet'
            },
            loop: true,
            autoplay: true
            // direction: 'vertical',
          });
        });
      });
    },
    toMore(path, id) {
      this.$router.push({
        path,
        query: {
          id
        }
      });
    },
    timeFormat(time) {
      let t = time.split(' ')[0];
      return t;
    }
  }
};