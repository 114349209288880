import CryptoJS from 'crypto-js/crypto-js'

// 默认的 KEY 与 iv 如果没有给
// const KEY = CryptoJS.enc.Utf8.parse("BrkOAWGD");//""中与后台一样  密码
// const IV = CryptoJS.enc.Utf8.parse();//""中与后台一样

/**
 * AES加密 ：字符串 key iv  返回base64
 */
export function encrypt(word){
  var key = CryptoJS.enc.Utf8.parse("YnJrb2F3YW5nZA==");
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return encrypted.toString();
}

/**
 * AES 解密 ：字符串 key iv  返回base64
 *
 */
export function decrypt(word){
  var key = CryptoJS.enc.Utf8.parse("YnJrb2F3YW5nZA==");
  var decrypt = CryptoJS.AES.decrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

export function encryptV2(word){
  var key = CryptoJS.enc.Utf8.parse("5ESutnn2XD$qRY*b");
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return encrypted.toString();
}
