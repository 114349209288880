import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue";
import _imports_0 from '../assets/address-icon.png';
const _hoisted_1 = {
  class: "container-views"
};
const _hoisted_2 = {
  class: "top-title mt-30"
};
const _hoisted_3 = {
  key: 0,
  class: "mainbox"
};
const _hoisted_4 = {
  class: "box"
};
const _hoisted_5 = {
  class: "list"
};
const _hoisted_6 = {
  class: "box"
};
const _hoisted_7 = {
  class: "list"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  class: "th"
};
const _hoisted_10 = {
  key: 1,
  class: "box_p"
};
const _hoisted_11 = {
  class: "flex-row pt-40"
};
const _hoisted_12 = {
  class: "flex-row align-center"
};
const _hoisted_13 = {
  key: 0,
  class: "fs-18",
  style: {
    "font-weight": "600"
  }
};
const _hoisted_14 = {
  class: "no-data-show"
};
const _hoisted_15 = {
  class: "flex",
  style: {
    "width": "370px"
  }
};
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  class: "flex ml-30",
  style: {
    "width": "260px"
  }
};
const _hoisted_18 = {
  class: "talent-list-item-name"
};
const _hoisted_19 = {
  class: "good-at flex-row mt-10",
  style: {
    "width": "370px"
  }
};
const _hoisted_20 = {
  class: "flex-row"
};
const _hoisted_21 = {
  class: "talent-list-item-institutions mt-10",
  style: {
    "width": "370px"
  }
};
const _hoisted_22 = {
  class: "flex-row align-center mt-10",
  style: {
    "width": "370px"
  }
};
const _hoisted_23 = {
  class: "talent-list-item-address-text ml-10"
};
const _hoisted_24 = ["onClick"];
const _hoisted_25 = {
  class: "flex-center mt-30",
  style: {
    "width": "400px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_selectComponent = _resolveComponent("selectComponent");
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[2] || (_cache[2] = _createTextVNode("当前位置： ")), _createElementVNode("span", {
    class: "cursor-pointer",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$back(0))
  }, "首页"), _cache[3] || (_cache[3] = _createTextVNode(">")), _createElementVNode("span", null, _toDisplayString($data.pageName), 1)]), false ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_cache[5] || (_cache[5] = _createElementVNode("div", {
    class: "title"
  }, " 首席法律咨询专家库 ", -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: "item",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.toHeadList && $options.toHeadList(...args))
  }, _cache[4] || (_cache[4] = [_createElementVNode("div", {
    class: "txt"
  }, "立即查看", -1)]))])]), _createElementVNode("div", _hoisted_6, [_cache[7] || (_cache[7] = _createElementVNode("div", {
    class: "title"
  }, " 法律咨询专家库 ", -1)), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.repoList, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "item",
      key: item.id,
      onClick: $event => $options.toTalentList(item.id)
    }, [_createElementVNode("span", _hoisted_9, _toDisplayString(item.name), 1), _cache[6] || (_cache[6] = _createElementVNode("span", {
      class: "tf"
    }, " 立即查看 ", -1))], 8, _hoisted_8);
  }), 128))])])])) : _createCommentVNode("", true), $data.isShow ? (_openBlock(), _createElementBlock("div", _hoisted_10, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (count23, ciybtUbdex) => {
    return _createElementVNode("div", {
      class: "mt-30 announcement-content-box",
      key: ciybtUbdex
    }, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [$data.selectList1[ciybtUbdex] ? (_openBlock(), _createElementBlock("div", _hoisted_13, "专家领域库：" + _toDisplayString($data.selectList1[ciybtUbdex].name), 1)) : _createCommentVNode("", true), ciybtUbdex == 99 ? (_openBlock(), _createBlock(_component_selectComponent, {
      key: 1,
      list: $data.selectList1,
      type: 1,
      currentItemValue: ciybtUbdex + 2,
      onSelectResult: $options.selectResult
    }, null, 8, ["list", "currentItemValue", "onSelectResult"])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.contentList, (item, index) => {
      return _openBlock(), _createElementBlock("li", {
        key: index,
        class: "list-item pb-30"
      }, [_createElementVNode("div", _hoisted_15, [_createElementVNode("img", {
        src: item.headPortraitUrl,
        alt: "",
        class: "img-photo-size"
      }, null, 8, _hoisted_16), _createElementVNode("div", _hoisted_17, [_createElementVNode("p", _hoisted_18, [_createTextVNode(_toDisplayString(item.name) + " ", 1), _createElementVNode("span", null, _toDisplayString($options.yearFormat(item.certificateStartTime)), 1)])])]), _createElementVNode("div", _hoisted_19, [_cache[8] || (_cache[8] = _createElementVNode("span", null, "擅长领域：", -1)), _createElementVNode("div", _hoisted_20, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.lawLabels, (item, index) => {
        return _openBlock(), _createElementBlock("p", {
          class: _normalizeClass({
            'ml-10': index !== 0
          }),
          key: index
        }, _toDisplayString(item.name), 3);
      }), 128))])]), _createElementVNode("p", _hoisted_21, "所属机构：" + _toDisplayString(item.unitName), 1), _createElementVNode("div", _hoisted_22, [_cache[9] || (_cache[9] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        class: "address-icon"
      }, null, -1)), _createElementVNode("span", _hoisted_23, _toDisplayString(item.area), 1)]), _createElementVNode("button", {
        class: "talent-list-item-button flex-center cursor-pointer mt-10",
        onClick: $event => $options.toDetail(item.id)
      }, " 查看详情 ", 8, _hoisted_24)]);
    }), 128))]), _createElementVNode("div", _hoisted_25, [_createVNode(_component_el_pagination, {
      background: "",
      layout: "prev, pager, next",
      onCurrentChange: $options.pageChange,
      "current-page": $data.page,
      total: $data.count
    }, null, 8, ["onCurrentChange", "current-page", "total"])])])]);
  }), 64))])) : _createCommentVNode("", true)]);
}