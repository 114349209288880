import { getNewsDetail } from '@/service/index.server';
export default {
  name: "announcementDetail",
  data() {
    return {
      pageName: '',
      detail: {},
      last: {},
      next: {},
      flag: 1
    };
  },
  mounted() {
    this.pageName = this.$route.name;
    this.getDetail(this.$route.query.id);
  },
  methods: {
    getDetail(id) {
      getNewsDetail({
        id
      }).then(res => {
        this.detail = res.data.this;
        if (res.data.last) {
          this.last = res.data.last;
        } else {
          this.last = {};
        }
        if (res.data.next) {
          this.next = res.data.next;
        } else {
          this.next = {};
        }
      });
    },
    lookOther(item) {
      this.getDetail(item.id);
      document.body.scrollTop = 0; // firefox
      document.documentElement.scrollTop = 0; // safari
      window.pageYOffset = 0;
    }
  }
};